import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "app",
    initialState: {
        data: {
            updated: false,
            images: [],
            products: [],
        },
        alreadyFetched: false,
        currentProduct: null
    },
    reducers: {
        setData: (state, action) => {
            state.data = { updated: true, ...action.payload };
        },
        setAlreadyFetched: (state, action) => {
            state.alreadyFetched = action.payload;
        },
        setCurrentProduct: (state, action) => {
            state.currentProduct = action.payload;
        },
    },
});

export const { setData, setCurrentProduct, setAlreadyFetched } = slice.actions;

export const selectData = (state) => state.app.data;
export const selectCurrentProduct = (state) => state.app.currentProduct;
export const selectAlreadyFetched = (state) => state.app.alreadyFetched;

export default slice.reducer;
