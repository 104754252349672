import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "uploader",
    initialState: {
        image: {
            url: null,
            fileName: null
        },
    },
    reducers: {
        setImage: (state, action) => {
            state.image = {...action.payload};
        },
    },
});

export const { setImage } = slice.actions;

export const selectImage = (state) => state.uploader.image;

export default slice.reducer;
