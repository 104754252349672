import React from "react";
import App from "../../app/App";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProduct, setCurrentProduct } from "../../app/slice";

const ProductSelector = () => {
    const { products } = App();
    const dispatch = useDispatch();
    const currentProduct = useSelector(selectCurrentProduct);

    return (
        <>
            <div className="col"></div>
            {!!products.length &&
                currentProduct &&
                products.map((product, i) => (
                    <div className="col px-4 px-lg-5 d-flex align-items-center justify-content-center" key={i}>
                        <div
                            className={`app-btn text-center ${
                                currentProduct.id === i ? "active" : ""
                            }`}
                            onClick={() =>
                                dispatch(
                                    setCurrentProduct({ id: i, ...product })
                                )
                            }
                        >
                            <div className={`app-round-icon my-3 app-icon-mask-${i}`}></div>
                            <h3 className="my-0">{product.title}</h3>
                        </div>
                    </div>
                ))}
            <div className="col"></div>
        </>
    );
};

export default ProductSelector;
