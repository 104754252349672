import React from "react";
import { useSelector } from "react-redux";
import { selectMask, selectIsLoading } from "./slice";

const Masker = () => {
    const url = useSelector(selectMask);
    const loading = useSelector(selectIsLoading);
    return (
        <>
            {url && <div className="app-design-holder-pic" style={{backgroundImage: `url('${url}')`}}></div>}
            {loading && <i className="fas fa-virus fa-4x fa-spin text-primary app-design-loader"></i>}
        </>
    );
};

export default Masker;
