// https://github.com/thednp/bootstrap.native
import "bootstrap.native/dist/polyfill"; //for IE
import "bootstrap.native/dist/bootstrap-native-v4";

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./app/store";

import Uploader from "./features/uploader/Uploader";
import DesignSelector from "./features/designSelector/DesignSelector";
import Masker from "./features/masker/Masker";
import PurchaseForm from "./features/purchaseForm/PurchaseForm";
import ProductSelector from "./features/productSelector/ProductSelector";

import "./../css/index.scss";

const store = configureStore({
    reducer: rootReducer,
});

ReactDOM.render(
    <Provider store={store}>
        <DesignSelector />
    </Provider>,
    document.getElementById("designSelector")
);
ReactDOM.render(
    <Provider store={store}>
        <Uploader />
    </Provider>,
    document.getElementById("uploader")
);
ReactDOM.render(
    <Provider store={store}>
        <Masker />
    </Provider>,
    document.getElementById("masker")
);
ReactDOM.render(
    <Provider store={store}>
        <ProductSelector />
    </Provider>,
    document.getElementById("productSelector")
);
ReactDOM.render(
    <Provider store={store}>
        <PurchaseForm />
    </Provider>,
    document.getElementById("purchaseForm")
);
