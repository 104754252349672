import 'whatwg-fetch';

import React from "react";
import { uppy } from "../uploader/Uploader";

import App from "../../app/App";
import { useDispatch, useSelector } from "react-redux";
import { setActiveUrl, selectActiveUrl } from "./slice";
import { setMask } from "../masker/slice";


const perPage = 12;

const Card = ({ url, active, onClick }) => {
    return (
        <div className="col p-3">
            <div
                className={`card border-0 ${active && "active"}`}
                onClick={onClick}
            >
                <img className="card-img-top" src={`${url}`} alt="" />
                <div className="app-ds-mask"></div>
                {active && (
                    <i className="fas fa-check-circle fa-lg app-ds-check"></i>
                )}
            </div>
        </div>
    );
};

const DesignSelector = () => {
    const { images } = App();
    const [currentPage, setPage] = React.useState(0);
    const totalPages = Math.ceil(images.length / perPage);

    const dispatch = useDispatch();
    const activeUrl = useSelector(selectActiveUrl);

    React.useEffect(() => {
        if (images.length > 0) {
            const defaultSelectedUrl = images[0];
            dispatch(setActiveUrl(defaultSelectedUrl));
            dispatch(setMask(defaultSelectedUrl));
        }
    }, [images]);

    const onCardClick = (url) => {
        dispatch(setActiveUrl(url));
        uppy.reset();
        dispatch(setMask(url)); //to masker
    };

    return (
        <>
            <div className="app-design-selector row row-cols-3">
                {images
                    .slice(
                        currentPage * perPage,
                        currentPage * perPage + perPage
                    )
                    .map((url) => (
                        <Card
                            url={url}
                            key={url}
                            active={url === activeUrl}
                            onClick={() => onCardClick(url)}
                        />
                    ))}
            </div>
            {totalPages > 1 && (
                <nav>
                    <ul className="pagination justify-content-center mt-2">
                        {currentPage > 0 && (
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => setPage(currentPage - 1)}
                                >
                                    Atgal
                                </button>
                            </li>
                        )}
                        {[...Array(totalPages)].map((page, i) => (
                            <li className={`page-item ${currentPage === i ? 'active' : ''}`} key={i}>
                                <button
                                    className="page-link"
                                    onClick={() => setPage(i)}
                                >
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                        {currentPage < totalPages - 1 && (
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => setPage(currentPage + 1)}
                                >
                                    Toliau
                                </button>
                            </li>
                        )}
                    </ul>
                </nav>
            )}
        </>
    );
};

export default DesignSelector;
