import "es6-promise/auto";
import "whatwg-fetch";

import React from "react";
import { Core as Uppy, XHRUpload } from "uppy";
import { DragDrop, ProgressBar } from "@uppy/react";

import { useDispatch, useSelector } from "react-redux";
import { setImage, selectImage } from "./slice";
import { setActiveUrl } from "../designSelector/slice";
import { setMask, setIsLoading } from "../masker/slice";

import "uppy/dist/uppy.min.css";

export const uppy = Uppy({
    debug: false,
    autoProceed: true,

    restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*"],
        maxFileSize: 10000000, //10M
    },
    locale: {
        strings: {
            exceedsSize: "Failas viršija maksimalų dydį",
        },
    },
});
uppy.use(XHRUpload, {
    endpoint: "upload.php",
    fieldName: "userFile",
});

const Uploader = () => {
    const dispatch = useDispatch();
    const { url, fileName } = useSelector(selectImage);

    const resetFilesObj = (files) => {
        Object.keys(files).forEach(function (key) {
            delete files[key];
        });
    };

    const resetUploader = () => {
        uppy.reset();
        dispatch(setMask(null)); //to masker
        dispatch(setIsLoading(false)); //to masker
    };

    uppy.setOptions({
        onBeforeFileAdded: (currentFile, files) => {
            resetFilesObj(files);
            resetUploader();
            dispatch(setActiveUrl(null)); //to designSelector
        },
    });

    uppy.on("upload", (data) => {
        dispatch(setIsLoading(true)); //to masker
    })
        .on("upload-success", (file, response) => {
            const url = response.uploadURL;
            const fileName = file.name;

            dispatch(setImage({ url, fileName }));
            dispatch(setMask(url)); //to masker
        })
        .on("cancel-all", () => {
            dispatch(setImage(null));
        });

    return (
        <>
            <div className="for-DragDrop">
                <DragDrop
                    uppy={uppy}
                    locale={{
                        strings: {
                            dropHereOr: "Nutempk čia arba %{browse}",
                            browse: "pasirink ;-)",
                        },
                    }}
                />
            </div>
            <div className="for-ProgressBar position-relative">
                <ProgressBar uppy={uppy} hideAfterFinish={false} />
            </div>
            {url && (
                <div className="uploaded-files text-left">
                    <h6 className="mt-3">Įkelti failai:</h6>
                    <ol className="list-unstyled">
                        <li className="d-flex justify-content-between">
                            {fileName}
                            <button
                                onClick={() => resetUploader()}
                                className="text-danger app-btn-remove fas fa-times-circle fa-lg"
                            ></button>
                        </li>
                    </ol>
                </div>
            )}
        </>
    );
};

export default Uploader;
