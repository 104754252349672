import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "masker",
    initialState: {
        mask: null,
        loading: false
    },
    reducers: {
        setMask: (state, action) => {
            state.mask = action.payload;
        },
        setIsLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setMask, setIsLoading } = slice.actions;

export const selectMask = (state) => state.masker.mask;
export const selectIsLoading = (state) => state.masker.loading;

export default slice.reducer;