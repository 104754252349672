import "whatwg-fetch";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setData,
    selectData,
    setCurrentProduct,
    setAlreadyFetched,
    selectAlreadyFetched,
} from "./slice";

const dataUrl = "http://m.asko.lt/json.php";

const App = () => {
    const dispatch = useDispatch();
    const data = useSelector(selectData);
    const alreadyFetched = useSelector(selectAlreadyFetched);

    React.useEffect(() => {
        if (!alreadyFetched) {
            dispatch(setAlreadyFetched(true)); //update data only once
            
            fetch(dataUrl)
                .then((res) => res.json())
                .then(
                    (result) => {
                        dispatch(setData(result));
                        //set first product as selected
                        !!result.products.length &&
                            dispatch(
                                setCurrentProduct({
                                    id: 0,
                                    ...result.products[0],
                                })
                            );
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }, []);

    return data;
};

export default App;
