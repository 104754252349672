import React from "react";
import { useSelector } from "react-redux";
//import { useForm } from "react-hook-form";
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";
import { selectMask } from "../masker/slice";
import { selectCurrentProduct } from "../../app/slice";

const delivery = 3;

const restrictCountInput = (event) => {
    const allow =
        (event.keyCode >= 48 && event.keyCode <= 57) || //keypad numbers
        (event.keyCode >= 96 && event.keyCode <= 105) || //numpad
        event.keyCode == 37 || //left
        event.keyCode == 39 || //right
        event.keyCode == 46 || //del
        event.keyCode == 8 || //back
        event.keyCode == 0 ||
        event.keyCode == 13; //enter
    !allow && event.preventDefault();
    return allow;
};
/*
const objToFormData = object => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
}, new FormData());
*/

const PurchaseForm = () => {
    const imageUrl = useSelector(selectMask);
    const product = useSelector(selectCurrentProduct);
    const price = product ? product.price / 100 : 0;

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const {
        register,
        errors,
        handleSubmit,
        formState,
        watch,
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
    });

    //use watch instead of useState https://react-hook-form.com/faqs#Whyisfirstkeystrokeisnotworking
    const count = watch("count") || 1;
    const values = getValues();

    const onSubmit = (data) => {
        setIsSubmitting(true);

        const queryString = new URLSearchParams(data);
        queryString.append("image", imageUrl);
        queryString.append("size", product.id);

        window.location.href = "request.php?" + queryString.toString();
    };
    const { touched } = formState;

    const inputValidClass = (name) => {
        return errors[name]
            ? "is-invalid"
            : touched.hasOwnProperty(name) && values[name] !== "" && "is-valid";
    };

    const input = ({
        name,
        errorText = null,
        required = true,
        minLength = 2,
        validations = {},
        ...params
    }) => {
        return (
            <>
                <input
                    className={`form-control app-input-${name} ${inputValidClass(
                        name
                    )} ${isSubmitting && "app-opacity-half"}`}
                    {...params}
                    name={name}
                    ref={register({
                        required: required,
                        minLength: minLength,
                        validate: (value) => value.trim() !== "",
                        ...validations,
                    })}
                    readOnly={isSubmitting}
                />
                {errors[name] && errorText && (
                    <div className="invalid-feedback d-block">{errorText}</div>
                )}
            </>
        );
    };

    return (
        product && (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                        {input({
                            name: "firstname",
                            placeholder: "Vardas",
                            errorText: "Įveskite Vardą",
                        })}
                    </div>
                    <div className="col-12 col-sm-6">
                        {input({
                            name: "lastname",
                            placeholder: "Pavardė",
                            errorText: "Įveskite Pavardę",
                        })}
                    </div>
                </div>
                <div className="form-group">
                    {input({
                        name: "email",
                        type: "email",
                        placeholder: "El. paštas",
                        validations: {
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            },
                        },
                        errorText: "Įveskite tikrą el. pašto adresą",
                    })}
                </div>
                <div className="form-group">
                    {input({
                        name: "phone",
                        placeholder: "Tel.",
                        minLength: 9,
                        errorText:
                            "Telefonas yra būtinas susisiekti iškilus neaiškumams",
                    })}
                </div>
                <div className="form-group">
                    {input({
                        name: "city",
                        placeholder: "Miestas",
                        errorText: "Įveskite miestą",
                    })}
                </div>
                <div className="form-group">
                    {input({
                        name: "street",
                        placeholder: "Pristatymo adresas",
                        errorText: "Įveskite adresą, kur pristatyti siuntą",
                    })}
                </div>
                <div className="form-group row">
                    <div className="col-6 d-flex flex-column align-items-center">
                        {input({
                            name: "zip",
                            placeholder: "Pašto kodas",
                            minLength: 5,
                            errorText:
                                "Pašto kodas reikalingas siuntų tarnybai",
                        })}
                    </div>
                    <div className="col-6 d-flex align-items-center">
                        <small>
                            <a
                                href="https://www.post.lt/pasto-kodu-ir-adresu-paieska"
                                target="_blank"
                            >
                                Pašto kodų paieška
                            </a>
                        </small>
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="border-0">
                                Dydis
                            </th>
                            <th scope="col" className="border-0 text-right">
                                Kiekis
                            </th>
                            <th
                                scope="col"
                                className="border-0 app-form-price-col"
                            >
                                Kaina
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="align-middle">{product.title}</td>
                            <td className="align-middle text-right">
                                <div className="input-group">
                                    <div className="input-group-prepend ml-auto">
                                        <button
                                            className="btn btn-outline-secondary app-form-border btn-sm"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                +count > 1 &&
                                                    setValue(
                                                        "count",
                                                        +count - 1
                                                    );
                                            }}
                                        >
                                            <i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                    {input({
                                        name: "count",
                                        type: "number",                                        
                                        minLength: 1,
                                        defaultValue: "1",
                                        step: "1",
                                        min: 1,
                                        validations: {
                                            min: 1,
                                        },
                                        onKeyDown: restrictCountInput,
                                    })}

                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary app-form-border btn-sm"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setValue("count", +count + 1);
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td className="align-middle">{count * price} €</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-right">Pristatymas</td>
                            <td>{delivery} €</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td className="align-middle text-right">
                                <strong>Bendra suma</strong>{" "}
                                <span className="text-muted">(su PVM)</span>
                            </td>
                            <td className="align-middle">
                                <h1 className="m-0">
                                    {count * price + delivery} €
                                </h1>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div className="form-group text-center mt-4">
                    {isSubmitting ? (
                        <div className="text-primary app-form-loader">
                            <span
                                className="spinner-grow spinner-grow-lg"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </div>
                    ) : (
                        <input
                            type="submit"
                            name="submit"
                            className="btn btn-secondary btn-lg"
                            value="Užsakyti"
                        />
                    )}
                </div>
            </form>
        )
    );
};

export default PurchaseForm;
