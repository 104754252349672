import { combineReducers } from "redux";
import appReducer from "../app/slice";
import dsReducer from "../features/designSelector/slice";
import upReducer from "../features/uploader/slice";
import maskReducer from "../features/masker/slice";

export default combineReducers({
    app: appReducer,
    designSelector: dsReducer,
    uploader: upReducer,
    masker: maskReducer,
});
