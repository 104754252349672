import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
    name: "designSelector",
    initialState: {
        activeUrl: null,
    },
    reducers: {
        setActiveUrl: (state, action) => {
            state.activeUrl = action.payload;
        },
    },
});

export const { setActiveUrl } = slice.actions;

export const selectActiveUrl = (state) => state.designSelector.activeUrl;

export default slice.reducer;
